@import '../../styles/colors';
@import '../../styles/vars';

.dropzone {
  flex: 1;
  font-weight: bold;
  font-size: $largeSizeText;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $primaryPadding;
  border-width: 2px;
  border-color: $primary-border-color;
  background-color: $primary-background-color;
  color: $secondary-font-color;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 110px;
  justify-content: center;
  background: $fourth-background-color;
  cursor: pointer;

  .fileInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .name {
      font-weight: bold;
      color: $primary-font-color;
    }

    .size {
      font-weight: normal;
    }
  }

  p {
  }

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      vertical-align: middle;

      &:first-child {
        font-size: 24px;
      }
      margin-left: 10px;
    }
  }
}

.label {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #90a0b7;
}
