@import '../../../styles/colors';
@import '../../../styles/vars';

.userInfo {
  margin: 20px 0;

  .nameIcon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px $color-icon-gray;
    border-radius: 50%;
    color: $color-icon-gray;
    font-family: $secondary-font-family;
  }

  .name {
    color: $color-table-gray;
    font-style: normal;
    font-weight: 500;
    font-size: $normalSizeText;
    line-height: 20px;
    font-family: $secondary-font-family;
  }

  .email {
    color: $color-icon-gray;
    font-size: $normalSizeText;
    line-height: 16px;
    font-weight: 500;
    font-style: normal;
    font-family: $primary-font-family;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      overflow-wrap: break-word;
      white-space: normal;
    }
  }
}

.onlyIcon {
  padding: 0 19px !important;
}
