@import '../../styles/common';

.topPanelComand {
  min-width: 20%;
  margin-right: 20px;
  line-height: 1.1em;
}

.position {
  font-size: $largeSizeText;
  font-weight: bold;
}

.institution {
  font-size: 14px;
  font-weight: normal;
  color: $color-gray;
}
