@import '../../styles/colors';
@import '../../styles/vars';

.textInput {
  padding: 5px 7px;
  border: none;
  //border-radius: 5px;
  font-size: $largeSizeText;
  line-height: 23px;
  font-family: $primary-font-family;
  border-bottom: solid 1px $secondary-border-color;
  border-radius: 0;

  &:focus {
    outline: none;
    border-color: $primary-focus-border-color;
  }
}
