@import '../../styles/vars';
@import '../../styles/colors';

.container {
  text-align: center;
  border-width: 0;
  font-family: $secondary-font-family;
  font-size: 18px;
  font-weight: 500;
  padding: 7px 9px;
  background: $primary-background-color;
  line-height: 16px;
  color: $red;
  border-radius: $primary-border-radius;
}
