@import '../../../styles/colors';
@import '../../../styles/vars';

/*TODO move this color to theme styles*/
$background: #ebf5fd;
$accent: #0460a9;
$defaultColor: #000;
$iconGray: #c2cfe0;

.tab {
  font-size: $largeSizeText;
  font-weight: bold;
  line-height: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  .icon {
    margin-right: 10px;
    width: 21px;
    height: 21px;
    path {
      &[fill] {
        fill: $iconGray;
      }
      &[stroke] {
        stroke: $iconGray;
      }
    }
  }

  &:hover {
    background: $lightGray;
  }

  &.active {
    background: $background;
    color: $accent;
    border-right: solid 4px;

    .icon path {
      &[fill] {
        fill: $accent;
      }

      &[stroke] {
        stroke: $accent;
      }
    }
  }

  &.short {
    .icon {
      margin-right: 0;
    }
  }
}
