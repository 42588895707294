@import 'colors';
@import 'vars';

.alignRight {
  text-align: right;
}

.container {
  padding: $primaryPadding;
  background-color: $white;
  height: 100%;
  box-shadow: -4px -4px 7px rgba(0, 0, 0, 0.03), 4px 4px 7px rgba(0, 0, 0, 0.03);
  overflow: auto;
}

.containerBottomPadding {
  padding: $primaryPadding $primaryPadding 0 $primaryPadding;
  & > :last-child {
    margin-bottom: $primaryPadding;
  }
}
.redText {
  color: $red;
}

.greenText {
  color: $green;
}

.blueText {
  color: $darkBlue;
}

.lightBlueText {
  color: $lightBlue;
}

.fadedText {
  color: $fadedBlack;
}

.fullWidth {
  width: 100%;
}

.tableFullHeight {
  max-height: 100%;
}

.tableWithHeader {
  height: 100%;
}

.tableWithControl {
  max-height: calc(100% - #{$inputHeight} - #{$headerHeight});
}

.columns {
  height: 100%;
  display: flex;

  .columnLeft {
    width: 350px;

    @media (max-width: 950px) {
      width: 50%;
    }
  }

  .columnRight {
    margin-left: $primaryPadding;
    flex-grow: 1;
  }
}

.content {
  width: 100%;
  height: 100%;
}
