@import '../../../styles/colors';
@import '../../../styles/vars';

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $titleHeight;

  .title {
    font-family: $primary-font-family;
    font-size: 20px;
    margin-left: 10px;
    color: $color-accent-blue;
  }
}
