@import 'vars';

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: local('Open Sans Bold'), url('./fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: local('Open Sans Italic'), url('./fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), url('./fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: $semiBold;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), url('./fonts/Roboto/Roboto.ttf') format('truetype');
}

//@font-face {
//  font-family: 'Roboto';
//  src: local('Roboto Medium'), url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
//}
