@import '../../../styles/colors';
@import '../../../styles/vars';

$iconGray: #c2cfe0;
$accent: #0460a9;

$button-size: 12px;
$border-size: 1px;
$container-size: $button-size + $border-size;

.switchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $iconGray;
  height: $button-size + 2 * $border-size + 2px;

  .btnContainer {
    background: $iconGray;
    border-radius: $container-size / 2;
    width: 2 * ($button-size + $border-size);
    height: $button-size + 2 * $border-size;
    padding: $border-size;
    margin: 0 5px;
    cursor: pointer;

    .btn {
      display: flex;
      width: $button-size;
      height: $button-size;
      background: #fff;
      border-radius: 50%;
      float: left;
    }
  }

  &.active {
    .btnContainer {
      background: $accent;
    }
    .btn {
      float: right;
    }
  }
}
