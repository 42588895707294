@import '../../../styles/common';
@import '../../../styles/colors';

.ratings {
  display: flex;
  justify-content: space-between;
  line-height: 0.9em;

  > div:not(:last-child) {
    margin-right: 20px;
  }
}
