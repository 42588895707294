@import '../../../styles/vars';

.common {
  resize: none;
  border-radius: 5px;
  border: 1px solid $secondary-border-color;
  font-size: $largeSizeText;
  font-family: $secondary-font-color;
  min-width: 150px;
}

.input {
  height: 35px;
  padding: 0 5px;
}

.textarea {
  width: 100%;
  padding: 5px;
  resize: none;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;
}

.readonly {
  border: none;
  padding: 0;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;

  p {
    margin: 0;
    font-size: $normalSizeText;
    line-height: 1.4em;
    padding: 0.3em 0 0 0;

    &:first-child {
      padding: 0;
    }
  }
}
