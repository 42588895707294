@import '../../../styles/colors';
@import '../../../styles/vars';

.addIcon {
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;

  svg path {
    &[stroke] {
      stroke: $color-icon-gray;
    }

    &[fill] {
      fill: $color-icon-gray;
    }
  }

  &:hover {
    svg path {
      &[stroke] {
        stroke: $color-accent-blue;
      }

      &[fill] {
        fill: $color-accent-blue;
      }
    }
  }
}
