@import 'vars';
@import 'colors';
@import 'common';
@import 'fonts';

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;

  * {
    box-sizing: border-box;
  }
}

#root {
  width: 100%;
  height: 100%;
}

h2 {
  font-size: 20px;
  margin: 0;
  height: $headerHeight;
  line-height: $headerHeight;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
