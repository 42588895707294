@import '../../styles/colors';

.container {
  width: 100%;
  height: 100%;
  background: transparent;

  .loader {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80px;
    height: 80px;
  }
  .loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $primaryBtn;
    animation: loader 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.2s;
  }
  .loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.1s;
  }
  .loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
}
