//Colors
$color-accent-blue: #0460a9;
$color-yellow: #ec9a1e;
$color-red: #e74a21;
$color-green: #00b366;
$color-black: #192a3e;
$color-table-black: #323c47;
$color-table-gray: #707683;
$color-dark-blue-text: #334d6e;
$color-gray: #90a0b7;
$color-icon-gray: #c2cfe0;
$color-white: white;

$color-dark-blue: #004983;
$color-light-blue: #0d87e9;
$color-vlight-blue: #effff8;
$color-light-gray: #f5f6f8;
$color-blue-bayoux: #5e7180;
$color-warning: red;
$color-alice-blue: #e6eff6;
$color-vlight-gray: #99a6b7;

// focus
$primary-focus-border-color: $color-light-blue;

//Border
$primary-border-color: $color-light-gray;
$secondary-border-color: $color-vlight-gray;
$third-border-color: #8c8c8c;

$primary-blackout-color: rgba(94, 113, 128, 0.4);

//shadow
$primary-shadow-color: $color-black;
$primary-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);

// Body
$primary-background-color: $color-white;
$secondary-background-color: $color-light-gray;
$third-background-color: $color-light-blue;
$fourth-background-color: $color-alice-blue;

$success-background-color: #effff8;
$warning-background-color: #f8f3f2;
$highlight-background-color: #ebf5fd;

//Fonts
$primary-font-family: 'Open Sans', sans-serif;
$secondary-font-family: 'Open Sans', sans-serif;

//Fonts. colors
$primary-font-color: $color-table-black;
$secondary-font-color: $color-accent-blue;
$light-font-color: $color-table-gray;
$vlight-font-color: $color-gray;
$third-font-color: $color-white;
$warning-font-color: $color-warning;

// Button
// Button. background/border color
$primary-button-color: $color-accent-blue;
$secondary-button-color: $color-light-blue;
$third-button-color: $color-white;

// Button disabled. background/border color
$primary-button-disabled-color: $color-icon-gray;
$secondary-button-disabled-color: $color-icon-gray;
$third-button-disabled-color: $color-white;

// Button pressed. background/border color
$primary-button-pressed-color: $color-dark-blue;
$secondary-button-pressed-color: $color-accent-blue;
$third-button-pressed-color: $color-white;

// Button. font color
$primary-button-font-color: $color-white;
$secondary-button-font-color: $color-accent-blue;
$third-button-font-color: $color-table-gray;
$button-font-color: $color-table-black;

// Button. border
$primary-button-border-color: $color-accent-blue;
$secondary-button-border-color: $color-gray;

// Button. border radius
$primary-border-radius: 4px;
