@import '../../../styles/vars';

.select {
  height: 35px;
  padding: 0 5px;
  border-radius: 5px;
  border: none;
  font-size: $largeSizeText;
  font-family: $primary-font-family;
  color: $secondary-font-color;

  option {
    &:disabled {
      color: #c2cfe0;
    }
    color: $primary-font-color;
  }
}
