@import '../../../styles/colors';
@import '../../../styles/vars';

$borderColor: #cccccc;

.tabPanel {
  width: 100%;
  position: absolute;
  background: $lightGray;
  z-index: 1;
  height: calc(100% - #{$topPanelHeight});

  .tabTitle {
    position: relative;
    padding: 0 $primaryPadding;
    font-weight: bold;
    line-height: $titleHeight;
    height: $titleHeight;
    font-size: 22px;
    border-bottom: 2px solid $borderColor;
    color: $darkBlue;
  }

  .tabBodyOuter {
    position: relative;
    height: calc(100% - #{$titleHeight});

    .tabBodyInner {
      padding: $primaryPadding;
      height: 100%;
      overflow: auto;
    }
  }
}

.hidden {
  z-index: 0;
}
