@import '../../../styles/colors';
@import '../../../styles/vars';

.topPanelIcon {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(194, 207, 224, 0.3);
    border-radius: 50%;
    margin-right: 15px;

    svg > path {
      &[fill] {
        fill: $color-gray;
      }

      &[stroke] {
        stroke: $color-gray;
      }
    }
  }

  .title {
    font-weight: normal;
    font-size: $normalSizeText;
  }

  .value {
    font-size: $largeSizeText;
    line-height: 16px;
    margin-top: 4px;
    color: $color-table-black;
    font-weight: bold;
  }
}
